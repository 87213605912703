<template>
  <AbstractFilter
    :on-filter="filterGallery"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="gallery_new"
    filter-panel-class="filter-panel-gallery"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('modal.title')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('system.id')"
    />
    <Select
      v-model="filter.site"
      :options="sites"
      :label="$t('modal.site')"
      id="filter_site"
    />
  </AbstractFilter>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import GalleryFilterModel from '@/model/GalleryFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'
import PermissionService from '@/services/PermissionService'
import { MODULE_MODULES_GALLERY } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'GalleryFilter',
  components: {
    AbstractFilter,
    Input,
    Select
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_GALLERY)
    }
  },
  data () {
    return {
      filter: this._.cloneDeep(GalleryFilterModel),
      isLoading: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.GALLERY_PERMISSIONS
    }
  },
  methods: {
    async apply () {
      this.$store.commit('gallery/setFilter', this.filter)
      this.$emit('search')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(GalleryFilterModel)
      this.apply()
    },
    filterGallery () {
      this.$store.commit('gallery/setPage', 1)
      this.apply()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['gallery/filter']
  },
  mounted () {
    this.resetFilter()
  }
}
</script>

<style lang="scss">
.filter-panel-gallery {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
